"use client";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";

export const deleteLoginCookies = () => {
  deleteCookie("isLoggedIn");
  deleteCookie("userUid");
  deleteCookie("refreshToken");
  deleteCookie("accessToken");
};
export const createLoginCookies = (accessTokenData) => {
  setCookie("isLoggedIn", 1);
  setCookie("userUid", accessTokenData.user.id);
};

export const hasCookieClient = (name) => {
  return hasCookie(name);
}

export const getCookieClient = (name) => {
  if (hasCookie(name)) return getCookie(name);

  return null;
};

export const setCookieClient = (name, value) => {
  if (!hasCookie(name)) setCookie(name, value);
  return null;
};

// import {
//   ApolloClient,
//   from,
//   gql,
//   HttpLink,
//   InMemoryCache,
// } from "@apollo/client";
// import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
// import { onError } from "@apollo/client/link/error";

// loadDevMessages();
// loadErrorMessages();

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// const httpLink = new HttpLink({ uri: process.env.API_PATH });
// console.log('here')
// export const aClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } }, // link,
//   link: from([errorLink, httpLink]),
// });

// export const Query = async (GET, variables) => {
//   return await aClient.mutate({
//     mutation: GET,
//     variables: { ...variables },
//   });
// };

// export const Mutation = async (GET, variables) => {
//   return await aClient.mutate({
//     mutation: GET,
//     variables: { ...variables },
//   });
// };

// export function getLocale(path, lang) {
//   const GET_LOCALE = gql`
//     query Query($path: String!, $lang: String!) {
//       locale(path: $path, lang: $lang) {
//         code
//         status
//         message
//         data
//       }
//     }
//   `;

//   return aClient.query({
//     query: GET_LOCALE,
//     variables: { path: path, lang: lang },
//   });
// }

// export const extApiGetQuery = async (query) => {
//   const url = `https://www.ratehawk.com/api/site/multicomplete.json?query=${query.query}&locale=${query.lang}`;
//   const res = await fetch(url);
//   // .then((response) => response.json())
//   // .then((data) => console.log(data));

//   if (!res.ok) {
//     // This will activate the closest `error.js` Error Boundary
//     throw new Error("Failed to fetch data");
//   }

//   return res.json();
// };

// export const extApiPostQuery = async (query) => {
//   // const url = 'https://www.ratehawk.com/api/site/multicomplete.json?query=Szczyrk&locale=pl';
//   const url = "https://api.worldota.net/api/b2b/v3/search/multicomplete/";
//   const res = await fetch(url, {
//     method: "POST",
//     headers: {
//       // Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(query),
//   });
//   // .then((response) => response.json())
//   // .then((data) => console.log(data));

//   if (!res.ok) {
//     // This will activate the closest `error.js` Error Boundary
//     throw new Error("Failed to fetch data");
//   }

//   return res.json();
// };

// export const langYear = (num) => {
//     num = parseInt(num);
//     switch (num) {
//         case(num === 0 || num >= 5):
//             return ' lat'
//         case(num > 0 && num < 5):
//             return ' lata';
//     }
// }
