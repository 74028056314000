'use client'
import {createContext, useContext, useState} from "react";

export const GoogleMapContext = createContext({
    Map: '',
    setMap: () => {}
});

export const GoogleMapContextProvider = ({children}) => {
    const [Map, setMap] = useState('')
    return <GoogleMapContext.Provider value={{Map, setMap}}>{children}</GoogleMapContext.Provider>
}
export const useGoogleMapContext = () => {
    return useContext(GoogleMapContext);
}