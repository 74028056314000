"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { redirect } from "next/navigation";
import { createLoginCookies, deleteLoginCookies } from "@/api/actions";
import { getCookie, getCookies, hasCookie } from "cookies-next";
import { navigate } from "@/app/lib/actions";

export const AuthContext = createContext({
  isLoggedIn: getCookie('isLoggedIn'),
  onLogin: () => { },
  onLogout: () => { },
});

export const AuthContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const accessTokenCookie = hasCookie('accessToken');
    !accessTokenCookie ? deleteLoginCookies() : setLoggedIn(true)
  }, [])

  const loginHandler = ({ authenticated, accessTokenData }) => {
    createLoginCookies(accessTokenData);
    setLoggedIn(authenticated);
  };

  const logOutHandler = () => {
    setLoggedIn(false);
    deleteLoginCookies();
    navigate("/")
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: loggedIn,
        onLogin: loginHandler,
        onLogout: logOutHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
