import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { getCookieServer } from "@/app/lib/actions";
import { getCookie } from "cookies-next";

if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = getCookie("accessToken") || getCookieServer("accessToken");
  const refreshToken = getCookie("refreshToken") || getCookieServer("refreshToken");
  // return the headers to the context so httpLink can read them

  let items = {};
  if (accessToken) {
    items = {
      "x-access-token": accessToken?.value ? accessToken.value : accessToken,
      "x-refresh-token": refreshToken?.value ? refreshToken.value : refreshToken
    };
  }

  return {
    headers: {
      ...headers,
      ...items,
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_PATH,
  credentials: "same-origin",
});

export const apolloClient = new ApolloClient({
  defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  cache: new InMemoryCache(),
  link: authLink.concat(ApolloLink.from([errorLink, httpLink])),
});

export const Query = async (GET, variables) => {
  return await apolloClient.mutate({
    mutation: GET,
    variables: { ...variables },
  });
};

export const Mutation = async (GET, variables) => {
  return await apolloClient.mutate({
    mutation: GET,
    variables: { ...variables },
  });
};

export function getLocale(path, lang) {
  const GET_LOCALE = gql`
    query Query($path: String!, $lang: String!) {
      locale(path: $path, lang: $lang) {
        code
        status
        message
        data
      }
    }
  `;

  return apolloClient.query({
    query: GET_LOCALE,
    variables: { path: path, lang: lang },
  });
}

export const ApolloProviderClient = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const executeGraphQl = async (query) => {
  const { loading, error, data } = await apolloClient.query(query);
  // console.log(loading, error, data)
  return { loading, error, data };
};
